.Home-portada {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    flex-direction: column;
    position: relative;
}

.Home-background {
    background: #1E1E1E;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.Home-image {
    position: relative;
    height: 100%;
    width: 100%;
}

.Home-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Home-content {
    max-width: 42.5%;
    width: 100%;
    position: absolute;
    top: 40vh;
}
@media only screen and (max-width: 850px) {
    .Home-content {
        max-width: 75%;
        width: 100%;
        position: absolute;
        top: 35vh;
    }
}

.Home-logo {
    fill: white;
    opacity: 0.8;
    filter: drop-shadow(20px 20px 48px #c27757);
}

.Home-separator {
    height: 1px;
    background-color: white;
    width: 0%;
    margin: 0 auto;
    margin-top: 38px;
    margin-bottom: 20px;
}
.expand-separator {
    width: 100%;
    animation: expandSeparator 1s ease-in-out;
    -webkit-animation: expandSeparator 1s ease-in-out;
    -moz-animation: expandSeparator 1s ease-in-out;
    -o-animation: expandSeparator 1s ease-in-out;
}

@keyframes expandSeparator {
    0% {        
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.not-expand-separator {
    width: 0%;
    animation: notExpandSeparator 1s ease-in-out;
    -webkit-animation: notExpandSeparator 1s ease-in-out;
    -moz-animation: notExpandSeparator 1s ease-in-out;
    -o-animation: notExpandSeparator 1s ease-in-out;
}

@keyframes notExpandSeparator {
    0% {        
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

.Home-title {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.Home-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 31px;
}
@media only screen and (max-width: 850px) {
    .Home-buttons {
        justify-content: space-around;
    }
}

.HomeButton-container {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    box-sizing: border-box;
    min-width: 120px;
    width: 20.5%;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    color: #fff;
    background: transparent;
}
/* .HomeButton-container:hover {
    animation: borderHover 0.5s ease-in-out;
    width: 37.5%;
    background: #1E1E1E;
} */
@keyframes borderHover {
    0% {
        width: 27.5%;
        background: transparent;
    }
    100% {
        width: 37.5%;
        background: #1E1E1E;
    }
}

@media only screen and (max-width: 850px) {
    .HomeButton-container {
        width: 40%;
        height: 35px;
    }
}

.button-black {
    border: 1px solid #1e1e1e;
    width: 100%;
    color: #1E1E1E;
    height: 3vh;
    margin-bottom: 0;
}

.HomeButton-text {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;
}


@media only screen and (max-width: 850px) {
    .HomeButton-text {
        font-size: 2vw;
    }
}

.Home-idiomes {
    position: absolute;
    top: 5%;
    right: 5%;
    color: white;
    z-index: 111;
}