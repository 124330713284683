.Arribar-portada {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152525;
    flex-direction: column;
    position: relative;
    padding: 10% 20.6%;
}

.Arribar-container {
    width: 100%;
}

.Arribar-title {
    font-family: 'DM Serif Display' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 64px;
    text-transform: unset !important;
    /* identical to box height, or 116% */

    text-align: left;
    color: #FFFFFF !important;
}

.Arribar-separator {
    width: 1px;
    height: 50px;

    background-color: #6F8A30;
    margin-top: 16px;
    margin-bottom: 2px;
}

.Arribar-text {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    text-transform: unset !important;
    /* or 150% */
    
    text-align: left;
    color: #FFFFFF !important;
}

.Arribar-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}

.Arribar-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Arribar-icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.Arribar-map {
    margin-top: 30px;
}

.Arribar-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 40px 0;
}

.Arribar-llista {
    color: white;
    margin-top: 5%;
    margin-left: 5%;
}

.Arribar-item {
    font-family: 'Quicksand' !important;
    color: white !important;
    margin-bottom: 2% !important;
    font-size: 12px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 850px) {
    .Arribar-item {
        margin-bottom: 2% !important;
        font-size: 10px !important;
    }
}

.Arribar-icon-map {
    fill: white !important;
    cursor: pointer;
    width: 3rem;
    transition: all 0.5s ease-in-out;
}
@media only screen and (max-width: 850px) {
    .Arribar-icon-map {
        width: 2rem;
    }
}

.icon-clicked {
    fill: #6F8A30 !important;
    width: 3.5rem;
    transition: all 0.5s ease-in-out;
}
@media only screen and (max-width: 850px) {
    .icon-clicked {
        fill: #6F8A30 !important;
        width: 2.5rem;
        transition: all 0.5s ease-in-out;
    }
}

.item-clicked {
    color: #6F8A30 !important
}

.icon-map-color {
    /* fill: white; */
}

/* .icon-map-color:hover {
    fill: yellow;
} */

.Arribar-rombo {
    fill: white;
    width: 10px;
    margin-right: 10px;
}

.Arribar-icon-maps {
    width: 120px;
    height: 60px;
}