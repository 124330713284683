.PinatellCarousel-container {
    width: 100%;
    height: 100%;
}

.PinatellCarousel-carousel {
    display: flex;
    height: 100%;
    width: 100%;
}

.PinatellCarousel-content {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.PinatellCarousel-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
}
.image-big {
    
}
.big-selected {
    opacity: 1;
    animation: bigSelected 0.5s ease-in-out;
}

@keyframes bigSelected {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.PinatellCarousel-img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 1s ease-in-out;
}

.image-auto {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    transition: all 1s ease-in-out;
}

.PinatellCarousel-background {
    height: 100%;
    width: 100%;
    background: #1e1e1e;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
}

.PinatellCarousel-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.PinatellCarousel-arrow-left {
    transform: rotate(-180deg);
    cursor: pointer;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 5%;
    width: 3em !important;
    height: 3em !important;
}
.PinatellCarousel-arrow-left:hover {
    opacity: 1;
}

.PinatellCarousel-arrow-right {
    transform: rotate(0);
    cursor: pointer;
    margin-left: 20px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 5%;
    width: 3em !important;
    height: 3em !important;
}
.PinatellCarousel-arrow-right:hover {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.MiniCarousel-container {
    position: absolute;
    bottom: 5%;
    z-index: 10;
    opacity: 1;
}
@media only screen and (max-width: 850px) {
    .MiniCarousel-container {
        left: 0%;
    }
}

.MiniCarousel-image {
    width: 200px;
    height: 140px;
    margin: 15px;
    position: relative;
    border: 1px solid white;
}

.MiniCarousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MiniCarousel-overflow {
    overflow: hidden;
}

.MiniCarousel-carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.MiniCarousel-arrows {
    max-width: 50vw;
    display: flex;
    justify-content: center;
}

.MiniCarousel-arrow-left {
    transform: rotate(-90deg);
    cursor: pointer;
    opacity: 0.75;
}
.MiniCarousel-arrow-left:hover {
    opacity: 1;
}

.MiniCarousel-arrow-right {
    transform: rotate(90deg);
    cursor: pointer;
    margin-left: 20px;
    opacity: 0.75;
}
.MiniCarousel-arrow-right:hover {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.MiniCarousel-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    opacity: 1;
    background: transparent;
}

.selected:hover {
    background: #000;
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
}

.not-selected {
    background: #000;
    opacity: 0.5;
}

.expand-width {
    max-width: 100vw;
    height: 100vh;
    animation: expandWidth 1s ease-in-out;
}

@keyframes expandWidth {
    0% {
        max-width: 90vw;
        height: 90vh;
    }
    100% {
        max-width: 100vw;
        height: 100vh;
    }
}

.clear {
    opacity: 0;
    animation: clear 1s ease-in-out;
    display: none;
}

@keyframes expandHeight {
    0% {
        opacity: 1;    
    }
    100% {
        opacity: 0;    
    }
}

.clear-background {
    opacity: 0;
    animation: clearBack 1s ease-in-out;
}

@keyframes clearBack {
    0% {
        opacity: 0.4;    
    }
    100% {
        opacity: 0;    
    }
}

.MiniCarousel-eye {
    width: 2em !important;
    height: 2em !important;
}

.PinatellCarousel-titles {
    position: absolute;
    top: 50vh;
    left: 0;
    color: white;
    padding-left: 5%;
}
@media only screen and (max-width: 850px) {
    .PinatellCarousel-titles {
        position: absolute;
        top: 50vh;
        left: 0;
        color: white;
        padding-left: 5%;
    }
}

.PinatellCarousel-title {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.PinatellCarousel-title-line {
    height: 1px;
    width: 0px;
    background-color: white;
    margin-right: 10px;
    transition: all 0.5s ease-in-out;
}

.title-selected {
    width: 30px;
    transition: all 0.5s ease-in-out;
}