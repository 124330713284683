.Entorn-portada {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152525;
    flex-direction: column;
    position: relative;
}

.Entorn-image {
    width: 100%;
    height: 100%;
}

.Entorn-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Entorn-background {
    background: #1E1E1E;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.Entorn-content {
    width: 100%;
    position: absolute;
    top: 50vh;
    text-align: center;
    opacity: 0;

}

.Entorn-title {
    font-family: 'DM Serif Display' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 64px;
    /* or 116% */


    color: #FFFFFF;
}

.Entorn-section {
    height: 100vh;
    /* padding-right: 20.6%;
    padding-left: 20.6%; */
    max-width: 80.1%;
}
@media only screen and (max-width: 850px) {
    .Entorn-section {
        flex-direction: column;
    }
}

.Entorn-separator {
    width: 1px;
    height: 50px;

    background-color: #6F8A30;
    margin-top: 16px;
    margin-bottom: 2px;
}

.Entorn-text {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */


    color: #1D1D1B !important;

}

.Entorn-section-left {
    max-width: 41%;
    width: 100%;
    position: relative;
    opacity: 0;
}
@media only screen and (max-width: 850px) {
    .Entorn-section-left {
        max-width: 80%;
    }
}

.Entorn-section-right {
    max-width: 35%;
    margin-left: 24px;
    width: 100%;
    opacity: 0;
}
@media only screen and (max-width: 850px) {
    .Entorn-section-right {
        max-width: 80%;
        margin-left: 0;
        margin-top: 24px;
    }
}


.Entorn-fixed {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    top: 0;
}

.Entorn-title-fixed {
    position: absolute;
    text-align: center !important;
    z-index: 9;
    top: 0;
    bottom: 0;
    margin: auto !important;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    opacity: 0.085;
    font-size: 10vw !important;
}


.fadeIn {
    animation: fadeIn 0.5s ease-in-out;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.085;
    }
}

.fadeOut {
    animation: fadeOut 0.5s ease-in-out;
}
@keyframes fadeOut {
    0% {
        opacity: 0.085;
    }
    100% {
        opacity: 0;
    }
}

