.GaleriaEdit-image-button {
    background: #152525;
    color: white;
    padding: 2%;
    border: 0;
    border-radius: 10px;
    box-shadow: rgb(0, 0, 0) 0px 0px 5px 0px;
    font-family: 'Quicksand';
    font-size: 20px;
    cursor:pointer;
}

.GaleriaEdit-delete {
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 2%;
    border-radius: 100%;
    background-color: #152525;
}