.Casa-portada {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152525;
    flex-direction: column;
    position: relative;
}

.Casa-image {
    width: 100%;
    height: 100%;
}

.Casa-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 850px) {
    .Casa-img {
        margin-top: 25px;
    }
}

.Casa-background {
    background: #1E1E1E;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
}

.Casa-content {
    max-width: 40%;
    width: 100%;
    position: absolute;
    left: 20.6%;
    top: 50vh;
    opacity: 0;
}
@media only screen and (max-width: 850px) {
    .Casa-content {
        max-width: 75%;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.Casa-title {
    font-family: 'DM Serif Display' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 64px;
    width: auto;
    /* or 116% */


    color: #FFFFFF;
}

.Casa-section {
    padding-top: 116px;
    padding-bottom: 116px;
    padding-right: 20.6%;
    padding-left: 20.6%;
    max-width: 50.1%;
    position: relative;
}
@media only screen and (max-width: 850px) {
    .Casa-section {
        padding-right: 0%;
        padding-left: 0%;
        max-width: 75%;
        margin: 0 auto;
        flex-direction: column;
    }
}

.Casa-separator {
    width: 1px;
    height: 50px;

    background-color: #6F8A30;
    margin-top: 16px;
    margin-bottom: 2px;
}

.Casa-text {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */


    color: #1D1D1B !important;

}

.Casa-section-left {
    max-width: 35%;
    width: 100%;
    position: relative;
    opacity: 0;
}
@media only screen and (max-width: 850px) {
    .Casa-section-left {
        max-width: 75%;
    }
}

.Casa-section-right {
    max-width: 35%;
    margin-left: 24px;
    width: 100%;
    opacity: 0;
}
@media only screen and (max-width: 850px) {
    .Casa-section-right {
        max-width: 75%;
        margin-top: 24px;
    }
}