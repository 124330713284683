.Galeria-portada {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152525;
    flex-direction: column;
    position: relative;
    /* padding: 10% 20.6%; */
}

.Galeria-image {
    max-width: 400px;
}

.Galeria-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}