.PinatellPortada-container {
    width: 100%;
}

.PinatellPortada-carousel {
    display: flex;
    height: 100vh;
}

.PinatellPortada-content {
    max-width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.PinatellPortada-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
}
.image-selected-portada {
    opacity: 1;
    animation: imageSelected 3s ease-in-out;
}

@keyframes imageSelected {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.image-not-selected-portada {
    opacity: 0;
    animation: imageNotSelected 3s ease-in-out;
}

@keyframes imageNotSelected {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.PinatellPortada-img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease-in-out;
}

.PinatellPortada-background {
    height: 100%;
    width: 100%;
    background: #1e1e1e;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
}

.PinatellPortada-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.PinatellPortada-arrow-left {
    transform: rotate(-180deg);
    cursor: pointer;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 5%;
    width: 3em !important;
    height: 3em !important;
}
.PinatellPortada-arrow-left:hover {
    opacity: 1;
}

.PinatellPortada-arrow-right {
    transform: rotate(0);
    cursor: pointer;
    margin-left: 20px;
    opacity: 0.75;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 5%;
    width: 3em !important;
    height: 3em !important;
}
.PinatellPortada-arrow-right:hover {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}
