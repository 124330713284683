.Menu-header {
    height: 8vh;
    background-color: transparent;
}

.hide-menu {
    opacity: 0;
    animation: hideMenu 0.5s ease-in-out;
    -webkit-animation: hideMenu 0.5s ease-in-out;
    -moz-animation: hideMenu 0.5s ease-in-out;
    -o-animation: hideMenu 0.5s ease-in-out;
    display: none;
}

@keyframes hideMenu {
    0% {
        opacity: 1;
        display: block;
    }
    99% {
        display: none;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

.show-menu {
    opacity: 1;
    animation: showMenu 0.5s ease-in-out;
    -webkit-animation: showMenu 0.5s ease-in-out;
    -moz-animation: showMenu 0.5s ease-in-out;
    -o-animation: showMenu 0.5s ease-in-out;
}

@keyframes showMenu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menu-white {
    background-color: #f5f5f5;
    animation: menuWhite 1s ease-in-out;
    -webkit-animation: menuWhite 1s ease-in-out;
    -moz-animation: menuWhite 1s ease-in-out;
    -o-animation: menuWhite 1s ease-in-out;
    height: 8vh;
    opacity: 0.9;
}

@keyframes menuWhite {
    0% {
        background-color: transparent;
        height: 0;
    }
    100% {
        background-color: #f5f5f5;
        height: 8vh;
    }
}

.menu-transparent {
    background-color: transparent;
    animation: menuTransparent 1s ease-in-out;
    -webkit-animation: menuTransparent 1s ease-in-out;
    -moz-animation: menuTransparent 1s ease-in-out;
    -o-animation: menuTransparent 1s ease-in-out;
    height: 0;
}

@keyframes menuTransparent {
    0% {
        background-color: #f5f5f5;
        height: 8vh;
    }
    100% {
        background-color: transparent;
        height: 0;
    }
}

.Menu-container {
    width: 100%;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
}

.menu-opened {
    height: 100vh;
    animation: menuOpen 1s ease-in-out;
    -webkit-animation: menuOpen 1s ease-in-out;
    -moz-animation: menuOpen 1s ease-in-out;
    -o-animation: menuOpen 1s ease-in-out;
}

@keyframes menuOpen {
    0% {
        height: 0;
    }
    50% {
        height: 25%;
    }
    100% {
        height: 100vh;
    }
}

.menu-closed {
    height: 0vh;
    animation: menuClose 1s ease-in-out;
    -webkit-animation: menuClose 1s ease-in-out;
    -moz-animation: menuClose 1s ease-in-out;
    -o-animation: menuClose 1s ease-in-out;
}

@keyframes menuClose {
    0% {
        height: 100vh;
    }
    100% {
        height: 0vh;
    }
}

.MenuPinatell-logo {
    position: absolute;
    top: 20vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 25%;
    color: white;
    display: flex;
    justify-content: center;
    z-index: 10;
    height: 6vh;
    cursor: pointer;
}

.logo-initial {
    top: 20vh;
    height: 20vh;
    max-height: 210px;
    animation: initialLogo 1s ease-in-out;
    -webkit-animation: initialLogo 1s ease-in-out;
    -moz-animation: initialLogo 1s ease-in-out;
    -o-animation: initialLogo 1s ease-in-out;
    width: 25%;
}
@keyframes initialLogo {
    0% {
        top: 1vh;
        width: 5%;
        height: 6vh;
    }
    100% {
        top: 20vh;
        width: 25%;
        height: 20vh;
    }
}

@media only screen and (max-width: 850px) {
    .logo-initial {
        
        animation: initialLogoMobile 1s ease-in-out;
        -webkit-animation: initialLogoMobile 1s ease-in-out;
        -moz-animation: initialLogoMobile 1s ease-in-out;
        -o-animation: initialLogoMobile 1s ease-in-out;
    }
}
@keyframes initialLogoMobile {
    0% {
        top: 1vh;
        width: 10%;
        height: 6vh;
    }
    100% {
        top: 20vh;
        width: 25%;
        height: 20vh;
    }
}

.logo-translated {
    top: 1vh;
    animation: translateLogo 1s ease-in-out;
    -webkit-animation: translateLogo 1s ease-in-out;
    -moz-animation: translateLogo 1s ease-in-out;
    -o-animation: translateLogo 1s ease-in-out;
    width: 5%;
    height: 6vh;
}
@keyframes translateLogo {
    0% {
        top: 20vh;
        width: 25%;
        height: 20vh;
    }
    100% {
        top: 1vh;
        width: 5%;
        height: 6vh;
    }
}

@media only screen and (max-width: 850px) {
    .logo-translated {
        top: 1vh;
        animation: translateLogoMobile 1s ease-in-out;
        -webkit-animation: translateLogoMobile 1s ease-in-out;
        -moz-animation: translateLogoMobile 1s ease-in-out;
        -o-animation: translateLogoMobile 1s ease-in-out;
        width: 10%;
        height: 6vh;
    }
}
@keyframes translateLogoMobile {
    0% {
        top: 20vh;
        width: 25%;
        height: 20vh;
    }
    100% {
        top: 1vh;
        width: 10%;
        height: 6vh;
    }
}

.Menu-logo {
    width: 100%;
    height: auto;
    fill: #fff;
}

.Menu-logo-black {
    fill: #000;
    color: #000;
    animation: menuLogoBlack 1s ease-in-out;
    -webkit-animation: menuLogoBlack 1s ease-in-out;
    -moz-animation: menuLogoBlack 1s ease-in-out;
    -o-animation: menuLogoBlack 1s ease-in-out;
}

@keyframes menuLogoBlack {
    0% {
        fill: #fff;
    }
    100% {
        fill: #000;
    }
}

.Menu-logo-white {
    fill: #fff;
    animation: menuLogoWhite 1s ease-in-out;
    -webkit-animation: menuLogoWhite 1s ease-in-out;
    -moz-animation: menuLogoWhite 1s ease-in-out;
    -o-animation: menuLogoWhite 1s ease-in-out;
}

@keyframes menuLogoWhite {
    0% {        
        fill: #000;
    }
    100% {
        fill: #fff;
    }
}

.show-on-open {
    opacity: 0;
    transform: translateX(50px);
}
.show-item-menu0 {
    animation: menuLogoWhite 1s ease-in-out;
    -webkit-animation: menuLogoWhite 1s ease-in-out;
    -moz-animation: menuLogoWhite 1s ease-in-out;
    -o-animation: menuLogoWhite 1s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu1 {
    animation: menuLogoWhite 1.2s ease-in-out;
    -webkit-animation: menuLogoWhite 1.2s ease-in-out;
    -moz-animation: menuLogoWhite 1.2s ease-in-out;
    -o-animation: menuLogoWhite 1.2s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu2 {
    animation: menuLogoWhite 1.4s ease-in-out;
    -webkit-animation: menuLogoWhite 1.4s ease-in-out;
    -moz-animation: menuLogoWhite 1.3s ease-in-out;
    -o-animation: menuLogoWhite 1.3s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu3 {
    animation: menuLogoWhite 1.6s ease-in-out;
    -webkit-animation: menuLogoWhite 1.6s ease-in-out;
    -moz-animation: menuLogoWhite 1.4s ease-in-out;
    -o-animation: menuLogoWhite 1.4s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu4 {
    animation: menuLogoWhite 1.8s ease-in-out;
    -webkit-animation: menuLogoWhite 1.8s ease-in-out;
    -moz-animation: menuLogoWhite 1.5s ease-in-out;
    -o-animation: menuLogoWhite 1.5s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu5 {
    animation: menuLogoWhite 2s ease-in-out;
    -webkit-animation: menuLogoWhite 2s ease-in-out;
    -moz-animation: menuLogoWhite 1.6s ease-in-out;
    -o-animation: menuLogoWhite 1.6s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}
.show-item-menu6 {
    animation: menuLogoWhite 2.2s ease-in-out;
    -webkit-animation: menuLogoWhite 2.2s ease-in-out;
    -moz-animation: menuLogoWhite 1.8s ease-in-out;
    -o-animation: menuLogoWhite 1.8s ease-in-out;
    opacity: 1;
    transform: translateX(0px);
}

@keyframes menuLogoWhite {
    0% {        
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}