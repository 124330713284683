.Idioma-container {
    display: flex;
}

.Idioma-item-container {
    min-width: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Idioma-item {
    margin: 0 5px;
    font-size: 20px;
    cursor: pointer;
}

.Idioma-item:hover {
    font-weight: bold;
}