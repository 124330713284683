.Preus-portada {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #152525;
    flex-direction: column;
    position: relative;
    /* padding: 10% 20.6%; */
}
@media only screen and (max-width: 850px) {
    .Preus-portada {
        padding-right: 0%;
        padding-left: 0%;
        padding-top: 25%;
        margin: 0 auto;
    }
}

.Preus-padding {
    padding: 2.5% 20.6%;
    margin-top: 30px;
}
@media only screen and (max-width: 850px) {
    .Preus-padding {
        padding: 0;
        max-width: 75%;
        margin: 0 auto;
        padding-bottom: 10%;
    }
}

.Preus-white {
    background: white;
    width: 100%;
}

.Preus-flex {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 850px) {
    .Preus-flex {
        flex-direction: column;
    }
}

.Preus-line {
    height: 1px;
    width: 100%;
    background: #1e1e1e;
    margin: 30px 0;
}

.Preus-llistat {
    max-width: 45%;
    width: 100%;
}
@media only screen and (max-width: 850px) {
    .Preus-llistat {
        max-width: 100%;
        margin-top: 25px;
    }
}

.Preus-reserves {
    max-width: 100%;
    width: 100%;
}

.Preus-blue {
    background: #152525;
    padding: 3% 5%;
}
@media only screen and (max-width: 850px) {
    .Preus-blue {
        padding: 10% 10%;
    }
}

.white {
    background: #fff;
}

.Preus-title {
    font-family: 'DM Serif Display' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 64px;
    text-transform: unset !important;
    /* identical to box height, or 116% */

    text-align: left;
    color: #FFFFFF !important;
}

.Preus-subtitle {
    text-transform: unset !important;
    font-weight: bold !important;
    margin-top: 10px !important;
    font-family: 'DM Serif Display' !important;
}

.Preus-separator {
    width: 1px;
    height: 50px;

    background-color: #6F8A30;
    margin-top: 16px;
    margin-bottom: 2px;
}

.Preus-text {
    font-family: 'Quicksand' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    text-transform: unset !important;
    /* or 150% */
    
    text-align: left;
    color: #FFFFFF !important;
}

.Preus-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}

.Preus-icons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.Preus-icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}