.Caracteristiques-container {
    margin-bottom: 30px;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
}

.Caracteristiques-title {
    text-transform: unset !important;
    font-weight: bold !important;
    margin-top: 10px !important;
    font-family: 'DM Serif Display' !important;
}

.Caracteristiques-content {
    display: flex;
    align-items: center;
}

.Caracteristiques-text {
    margin-left: 10px !important;
    font-family: 'Quicksand' !important;
}

.opened {
    height: auto;
    min-height: 100vh;
    animation: opened 1s ease-in-out;
}

@keyframes opened {
    0% {
        min-height: 0;
    }
    100% {
        min-height: 100vh;
    }
}

.closed {
    height: 0;
    min-height: 0;
    animation: closed 1s ease-in-out;
}

@keyframes closed {
    0% {
        min-height: 100vh;
        height: auto;
    }
    100% {
        min-height: 0;
        height: 0;
    }
}

.Caracteristiques-rombo {
    fill: #6F8A30;
    width: 10px;
}
@media only screen and (max-width: 850px) {
    .Caracteristiques-rombo {
        width: 8px !important;
    }
}